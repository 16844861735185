import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Auth/Login.vue'
import PaymentsGetReceipt from '../views/Auth/PaymentsGetReceipt.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import DashboardIndex from '../views/Dashboard/DashboardIndex.vue'
import Tickets from '../views/Dashboard/Travels/Tickets/Tickets.vue'
import Payments from '../views/Dashboard/Travels/Payments/Payments.vue'
import BankCards from '../views/Dashboard/Travels/BankCards/BankCards.vue'
import AddBankCards from '../views/Dashboard/Travels/BankCards/AddBankCards.vue'
import Account from '../views/Dashboard/Account/Account.vue'

import NotFound from '../views/NotFound.vue'

const routes = [
  { 
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      auth: false
    }
  },
  { 
    path: '/payments/get_receipt',
    name: 'PaymentsGetReceipt',
    component: PaymentsGetReceipt
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'DashboardIndex',
        component: DashboardIndex,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/tickets',
        name: 'Tickets',
        component: Tickets,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/payments',
        name: 'Payments',
        component: Payments,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/bank_cards',
        name: 'BankCards',
        component: BankCards,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/bank_cards/add',
        name: 'AddBankCards',
        component: AddBankCards,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account',
        name: 'Account',
        component: Account,
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if ('auth' in to.meta && to.meta.auth && localStorage.getItem('session_account') == null) {
    return next({
      path: '/'
    })
  } else if ('auth' in to.meta && !to.meta.auth && localStorage.getItem('session_account')) {
    return next({
      path: '/dashboard'
    })
  } else {
    return next()
  }

})
export default router
<template>
    <div class="d-flex justify-content-center flex-wrap p-4">
        <div class="col-12 col-lg-8 col-xxl-5 d-flex flex-wrap border rounded-4 bg-white p-4">
            <div class="receipt col-12 rounded-4 bg-white p-4" id="receipt" v-if="receipt">
                <div class="d-flex flex-wrap mb-4">
                    <div class="col-12 col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                        <img :src="require(`@/assets/img/tenants/${omsApiKey}/${omsApiKey}.png`)" class="w-50">
                    </div>
                    <div class="col-12 col-sm-6 text-center text-sm-end">
                        <h5 class="text-third">
                            {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.receipt') }} n.
                            <span class="text-second">
                                {{ receipt.payment.short_id }}
                            </span>
                        </h5>
                    </div>
                </div>
                <div class="d-flex align-items-center flex-wrap mb-4">
                    <div class="col-12 col-sm-6 text-center text-sm-start mb-2 mb-sm-0">
                        <h5 class="text-third">
                            {{ account ? `${account.name} ${account.surname}` : null }}
                        </h5>
                    </div>
                    <div
                        class="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end align-items-center flex-wrap">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(receipt.bank_card.label)}.svg`)"
                            class="card-scheme">
                        <span class="text-second fs-5">
                            {{ receipt.bank_card.truncated_pan }}
                            <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third"
                                v-if="receipt.bank_card.form_factor === 'DEVICE'"></i>
                        </span>
                    </div>
                </div>
                <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-book-atlas me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.fare') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-clock me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.valid_from') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-clock me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.valid_to') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-money-bill-1-wave me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.amount') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-location-dot"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ticket, index) in receipt.tickets" :key="index">
                                <td class="text-center align-middle">
                                    {{ ticket.fare_name }}
                                    <br>
                                    <span class="fw-bold">
                                        {{ this.$formatAmount(ticket.fare_amount, receipt.payment.currencies_code) }}
                                    </span>
                                </td>
                                <td class="text-center align-middle">
                                    {{ this.$formatDate(ticket.valid_from, this.locale, ticket.time_zone) }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ this.$formatDate(ticket.valid_to, this.locale, ticket.time_zone) }}
                                </td>
                                <td class="text-center align-middle">
                                    <span class="fw-bold">
                                        {{ this.$formatAmount(ticket.ticket_amount, receipt.payment.currencies_code) }}
                                    </span>
                                </td>
                                <td class="text-center align-middle">
                                    <span v-for="(transit_data, index) in ticket.first_validation_transit_data"
                                        :key="index">
                                        <span class="badge rounded bg-third">
                                            {{ transit_data.key }}
                                        </span>
                                        <span class="badge rounded bg-second ms-2">
                                            {{ transit_data.val }}
                                        </span>
                                    </span>
                                    <h6
                                        v-if="ticket.last_validation_transit_data && ticket.last_validation_transit_data.length > 0">
                                        - - - - - -
                                    </h6>
                                    <span v-for="(transit_data, index) in ticket.last_validation_transit_data"
                                        :key="index">
                                        <span class="badge rounded bg-third">
                                            {{ transit_data.key }}
                                        </span>
                                        <span class="badge rounded bg-second ms-2">
                                            {{ transit_data.val }}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mb-5">
                    <h6 class="text-third">
                        *{{ $t('Dashboard.Payments.Modals.PaymentReceipt.fiscal_warn') }}
                    </h6>
                </div>
                <div class="d-flex flex-wrap">
                    <div class="col-12 col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                        <h6 class="text-dark fw-bold">
                            Trenord S.r.l
                        </h6>
                        <small>
                            Piazzale Cadorna, 14 <br>
                            20123 Milano - Italia <br>
                            Partita IVA: IT06705490966 <br>
                            <a href="https://www.trenord.it/" target="_blank" rel="noopener noreferrer">
                                https://www.trenord.it/
                            </a>
                            <br>
                            <a href="https://www.malpensaexpress.it/" target="_blank" rel="noopener noreferrer">
                                https://www.malpensaexpress.it/
                            </a>
                        </small>
                    </div>
                    <div class="col-12 col-sm-6 text-center text-sm-end align-self-end">
                        <h6 class="text-third fw-bold">
                            {{ currentTimeStamp }}
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-12 text-end" v-if="receipt">
                <span class="btn btn-auth btn-auth-second" @click="downloadReceipt">
                    <i class="fa-solid fa-download me-2"></i>
                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.download') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import html2pdf from "html2pdf.js"
export default {
    name: 'PaymentsGetReceipt',
    data() {
        return {
            receipt: null,
            currentTimeStamp: null,
            account: null
        }
    },
    computed: {
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    methods: {
        ...mapMutations(['setLoader', 'setErrorFromServer']),
        async getReceipt(short_id) {
            try {
                const response = await this.$http.post(`${this.omsApiPath}/payments/get_info`, { short_id }, {
                    headers: {
                        "api-key": this.omsApiKey
                    }
                })
                var info = response.data
                for (var i = 0; i < info.tickets.length; i++) {
                    var ticket = info.tickets[i]

                    if (ticket.first_validation_transit_data) {
                        var first_validation_transit_data = []
                        for (const [key, val] of Object.entries(ticket.first_validation_transit_data)) {
                            first_validation_transit_data.push({ key, val })
                        }
                        ticket.first_validation_transit_data = first_validation_transit_data
                    }

                    if (ticket.last_validation_transit_data) {
                        var last_validation_transit_data = []
                        for (const [key, val] of Object.entries(ticket.last_validation_transit_data)) {
                            last_validation_transit_data.push({ key, val })
                        }
                        ticket.last_validation_transit_data = last_validation_transit_data
                    }
                }
                this.locale = navigator.language.slice(0, 2)
                this.currentTimeStamp = this.$formatDate(moment(), this.locale, Intl.DateTimeFormat().resolvedOptions().timeZone)
                try {
                    const bank_card_data = await this.$http.post(`${this.apiPath}/bank_card/get`, { alias_pan: info.bank_card.alias_pan }, {
                        headers: {
                            'x-api-key': this.omsApiKey
                        }
                    })
                    this.account = bank_card_data.data
                } catch (err) {
                    this.setErrorFromServer(err.response.data)
                    this.setLoader(false)
                }
                return info
            } catch (err) {
                this.setErrorFromServer(err.response.data)
                this.setLoader(false)
            }
        },
        async downloadReceipt() {
            await html2pdf(document.getElementById('receipt'), {
                margin: 1,
                filename: `${this.$t('Dashboard.Payments.Modals.PaymentReceipt.labels.receipt')}-${this.receipt.payment.short_id}.pdf`
            })
        }
    },
    async mounted() {
        this.setLoader(true)
        if (Object.keys(this.$route.query).length === 0) {
            this.setErrorFromServer(`Missing 'payment_short_id' field in GET request string`)
            this.setLoader(false)
            return
        }
        if (!this.$route.query.payment_short_id) {
            this.setErrorFromServer(`Missing 'payment_short_id' field in GET request string`)
            this.setLoader(false)
            return
        }
        this.receipt = await this.getReceipt(this.$route.query.payment_short_id)
        await this.setLoader(false)
        if (this.receipt !== null && this.receipt !== undefined) {
            await this.downloadReceipt()
        }
    }
}
</script>

<style></style>